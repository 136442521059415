<template>
  <div>

    <b-form-file
      v-model="file"
      accept=".xlsx"
      placeholder="Choose an Excel file..."
      drop-placeholder="Drop file here..."
    />
    <div class="d-flex mt-1 justify-content-between">
      <b-button @click="downloadTemplate">
        Download Sample
      </b-button>
      <b-button @click="handleSubmit">
        Upload and Process
      </b-button>
    </div>
    <b-alert
      v-if="validationErrors.length"
      show
      variant="danger"
    >
      <ul>
        <li
          v-for="(error, index) in validationErrors"
          :key="index"
        >
          {{ error }}
        </li>
      </ul>
    </b-alert>
  </div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx'
import { BAlert, BButton, BFormFile } from 'bootstrap-vue'

export default {
  components: {
    BFormFile,
    BButton,
    BAlert,
  },
  props: {
    currentResearchType: {
      type: String,
      required: true,
    },
    researchID: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
      validator: value => ['en', 'ar'].includes(value), // Only allow 'en' or 'ar'
    },
  },
  data() {
    return {
      file: null,
      questionTypes: ['drawing', 'multichoice', 'trueorfalse', 'typing', 'perceptual'], // Predefined question types
      validationErrors: [],
    }
  },
  methods: {
    downloadTemplate() {
      const ws_data = [
        ['title', 'type', 'description', 'choices', 'answerChoiceNum', 'order', 'hasTimer'], // Headers
        ['Sample Title', 'Single Choice', 'Sample Description', 'Choice 1;Choice 2;Choice 3', 1, 1, true], // Example row
      ]

      const ws = XLSX.utils.aoa_to_sheet(ws_data)

      // Define the dropdown options for the "type" column
      const questionTypes = ['drawing', 'multichoice', 'trueorfalse', 'typing', 'perceptual']

      // Add data validation for the "type" column
      for (let i = 2; i <= 100; i++) { // Adjust the range as needed
        ws[`B${i}`] = { v: '', s: { validation: { list: questionTypes } } }
      }

      // Add data validation rules
      ws['!dataValidations'] = [
        {
          sqref: 'B2:B100', // Range for the dropdown
          type: 'list',
          formula1: `"${questionTypes.join(',')}"`,
          allowBlank: true,
          showInputMessage: true,
          showErrorMessage: true,
          errorTitle: 'Invalid Type',
          error: 'Please select a valid question type.',
          promptTitle: 'Question Type',
          prompt: 'Select a question type from the dropdown list.',
        },
      ]

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Questions Template')

      XLSX.writeFile(wb, 'questions_template.xlsx')
    },
    async handleSubmit() {
      this.validationErrors = [] // Reset validation errors
      if (this.file) {
        try {
          const data = await this.processFile(this.file)
          if (data) {
            // Send data to Backendless
            await this.sendToBackendless(data)
          }
        } catch (error) {
          this.validationErrors.push(error)
        }
      }
    },
    async processFile(file) {
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.onload = event => {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const json = XLSX.utils.sheet_to_json(worksheet)

          // Validate JSON data
          const validationResults = this.validateExcelData(json)
          if (validationResults.valid) {
            const transformedData = json.map(row => this.transformRowToQuestion(row))
            resolve(transformedData)
          } else {
            this.validationErrors = validationResults.errors
            resolve(null)
          }
        }
        reader.onerror = error => reject(error)
        reader.readAsArrayBuffer(file)
      })
    },
    validateExcelData(data) {
      const errors = []
      data.forEach((item, rowIndex) => {
        const rowNumber = rowIndex + 2 // Adjust for 1-based index and header row
        if (!item.title) errors.push(`Row ${rowNumber}: 'title' is required.`)
        if (!item.type) {
          errors.push(`Row ${rowNumber}: 'type' is required.`)
        } else if (!this.questionTypes.includes(item.type)) {
          errors.push(`Row ${rowNumber}: 'type' must be one of ${this.questionTypes.join(', ')}.`)
        }
        if (!item.description) errors.push(`Row ${rowNumber}: 'description' is required.`)
        if (item.order < 0 || item.order > 9999) {
          errors.push(`Row ${rowNumber}: 'order' must be between 0 and 9999.`)
        }
        if (typeof item.hasTimer !== 'boolean') {
          errors.push(`Row ${rowNumber}: 'hasTimer' must be a boolean.`)
        }
        const choices = item.choices ? item.choices.split(';') : []
        if (choices.length < 2 || choices.length > 100) {
          errors.push(`Row ${rowNumber}: 'choices' must have between 2 and 100 items.`)
        }
        if (item.answerChoiceNum <= 0 || item.answerChoiceNum > choices.length) {
          errors.push(`Row ${rowNumber}: 'answerChoiceNum' must be between 1 and ${choices.length}.`)
        }
      })

      return {
        valid: errors.length === 0,
        errors,
      }
    },
    transformRowToQuestion(row) {
      const choices = row.choices.split(';')
      return {
        Type: row.type,
        ResearchID: this.researchID,
        TitleEN: row.title,
        TitleAR: row.title,
        DescriptionEN: row.description,
        title: { [this.language]: row.title },
        NumberOfChoices: choices.length,
        Choices: choices.map(choice => ({ [this.language]: choice })),
        AnswerChoiceNum: row.answerChoiceNum,
        Timer: row.hasTimer,
        ownerId: this.userId,
        order: parseInt(row.order, 10),
        Image: null,
        Video: null,
        Audio: null,
        File: null,
      }
    },
    async sendToBackendless(data) {
      try {
        const createdQuestions = await Backendless.Data.of('Questions').bulkCreate(data)

        await Backendless.Data.of('Researches').addRelation(this.researchID, 'questions', createdQuestions)

        this.$bvToast.toast('Questions imported successfully!', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })

        this.$emit('refresh-questions')
      } catch (error) {
        this.$bvToast.toast('Failed to import questions.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        console.error(`Server reported an error - ${error.message}`)
      }
    },
  },
}
</script>
