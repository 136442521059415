import LocalizedString from './LocalizedString'

/**
 * @class Question
 * @classdesc wrapper of the data of a single question attached to a research
 */
export default class Question {
  /**
     * @constructor
     * @param {string} id - the id of the question
     * @param {LocalizedString} title - the title of the question
     * @param {string} type - the type of the question
     * @param {string} researchID - the id of the research the question is attached to
     * @param {string} image - the image used in the question (if any)
     * @param {string} video - the video used in the question (if any)
     * @param {string} audio - the audio used in the question (if any)
     * @param file
     * @param {LocalizedString} description - the description of the question
     * @param {number} numberOfChoices - the number of choices in the question
     * @param {LocalizedString[]} choices - the choices in the question
     * @param {number} answerChoiceNum - the number of the correct choice
     * @param {boolean} hasTimer - whether the question has a timer
     * @param order
     */
  constructor({
    id = '',
    title = new LocalizedString(),
    type = '',
    researchID = '',
    image = null,
    video = null,
    audio = null,
    file = null,
    description = '',
    numberOfChoices = 2,
    choices = [new LocalizedString(), new LocalizedString()],
    answerChoiceNum = 0,
    hasTimer = false,
    order = 0,
  } = {}) {
    this.id = id
    this.title = title
    this.type = type
    this.researchID = researchID
    this.image = image
    this.video = video
    this.audio = audio
    this.description = description
    this.numberOfChoices = numberOfChoices
    this.choices = choices
    this.answerChoiceNum = answerChoiceNum
    this.hasTimer = hasTimer
    this.order = order
    this.file = file
  }
}
