<template>
  <b-modal
    id="modal-prevent-closing"
    ref="modal"
    :title="title"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="$emit('reset-modal')"
    @hidden="
      {
        step = 0;
        $emit('reset-modal');
      }
    "
    @ok="handleSubmit()"
  >
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <b-form>
        <!-- Mark:- description EN -->
        <div v-if="step === 0">
          <form-text-input
            v-model="question.description"
            label="Description"
            name="Description"
            rules="required"
          />
          <span
            v-if="researchType==='various'"
            class="mb-2"
          >Question Type</span>
          <v-select
            v-if="researchType==='various'"
            id="type"
            v-model="question.type"
            class="my-1"
            :required="true"
            :name="'type'"
            :options="['drawing', 'multichoice', 'trueorfalse', 'typing', 'perceptual']"
          />
          <span>Resources</span>
          <b-row class="m-1">
            <b-form-checkbox
              v-model="selectedImage"
              class="pr-2"
            >
              Image
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selectedVideo"
              class="pr-2"
            >
              Video
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selectedAudio"
              class="pr-2"
            >
              Audio
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selectedFile"
              class="pr-2"
            >
              File
            </b-form-checkbox>
          </b-row>

          <b-form-group v-if="selectedImage">
            <label class="d-inline-block text-sm-left mr-50">Image</label>
            <!-- render a preview if a file is selected or question.image is a url, otherwise show the file upload -->
            <b-container v-if="question.image">
              <b-row>
                <b-col cols="8">
                  <b-img
                    :src="imagePreview"
                    fluid
                    alt="Image Preview"
                  />
                </b-col>
                <b-col cols="4">
                  <b-button
                    variant="outline-danger"
                    @click="question.image = null"
                  >
                    Remove
                  </b-button>
                </b-col>
              </b-row>
            </b-container>

            <b-form-file
              v-if="!question.image"
              v-model="question.image"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="image/jpeg, image/png, image/jpg"
            />
          </b-form-group>

          <b-form-group v-if="selectedVideo">
            <label
              class="d-inline-block text-sm-left mr-50"
              for="video"
            >Video</label>
            <b-form-file
              id="video"
              v-model="question.video"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".mp4, .mov"
            />
          </b-form-group>

          <b-form-group v-if="selectedAudio">
            <label
              class="d-inline-block text-sm-left mr-50"
              for="audio"
            >Audio</label>
            <b-form-file
              id="audio"
              v-model="question.audio"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".mp3"
            />
          </b-form-group>
          <b-form-group v-if="selectedFile">
            <label
              class="d-inline-block text-sm-left mr-50"
              for="file"
            >File</label>
            <b-form-file
              id="file"
              v-model="question.file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="application/pdf,application/vnd.ms-powerpoint,application/epub+zip,application/x-mobipocket-ebook,application/msword"
            />
          </b-form-group>

          <!-- Mark:- Number of Choices -->
          <form-text-input
            v-model="question.numberOfChoices"
            label="Number of Choices"
            name="Number of Choices"
            rules="required|between:1,100"
            type="number"
          />
          <b-form-group v-if="researchHasAutoCorrect">
            <span style="font-size: 12px">Index of the Answer</span>
            <v-select
              v-model="question.answerChoiceNum"
              title="Select an item"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="answersArray"
            />
          </b-form-group>

          <form-text-input
            v-model="question.order"
            label="Sorting Order"
            name="Sorting Order"
            rules="required|between:0,9999"
            type="number"
          />
          <b-form-checkbox
            v-model="question.hasTimer"
            switch
          >
            <span>Time Constrained</span>
          </b-form-checkbox>
        </div>

        <div v-for="(lang, index) in languages">
          <div
            v-if="index === step - 1"
            :key="lang.code"
          >
            <form-text-input
              v-model="question.title[lang]"
              :label="`Title in ${LanguagesDict[lang].name}`"
              :name="`Title in ${LanguagesDict[lang].name}`"
              rules="required"
            />

            <!-- Mark list of choices -->
            <div
              v-for="(choice, index) in question.choices"
              :key="index"
            >
              <form-text-input
                v-model="choice[lang]"
                :label="`Choice ${index + 1} in ${LanguagesDict[lang].name}`"
                :name="`Choice ${index + 1} in ${LanguagesDict[lang].name}`"
                rules="required"
              />
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        @click="
          () => {
            if (step === 0) cancel();
            else step -= 1;
          }
        "
      >{{ step === 0 ? "Cancel" : "Prev" }}
      </b-button>
      <b-button
        variant="primary"
        @click="
          {
            if (step >= languages.length) $refs.observer.validate().then((success) => success ? ok() : null);
            else $refs.observer.validate().then((success) => success? (step += 1) : null)
          }
        "
      >{{ step >= languages.length ? "Submit" : "Next" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormCheckbox,
  BFormFile,
  BForm,
  BRow,
  BContainer,
  BImg,
  BButton,
  BCol,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import LocalizedString from '@/utils/LocalizedString'
import Question from '@/utils/Question'
import FormTextInput from '@/components/FormTextInput.vue'
import { LanguagesDict } from '@/utils/Languages'

export default {
  components: {
    BModal,
    BImg,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    ValidationObserver,
    BForm,
    BRow,
    BContainer,
    BCol,
    vSelect,
    FormTextInput,
  },
  props: {
    question: {
      type: Question,
      default: () => new Question(),
    },
    title: {
      type: String,
      default: 'Add Question',
    },
    researchHasAutoCorrect: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => ['en', 'ar'],
    },
    researchType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      step: 0,
      selectedImage: false,
      selectedVideo: false,
      selectedAudio: false,
      selectedFile: false,
      LanguagesDict,
      qType: null,
    }
  },

  emits: ['onSubmit', 'resetModal'],
  computed: {
    imagePreview() {
      return this.question.image instanceof File
        ? URL.createObjectURL(this.question.image)
        : this.question.image
    },
    answersArray() {
      return this.question.choices.map((choice, index) => index + 1)
    },
  },
  watch: {
    question(newVal, _) {
      this.selectedImage = !!newVal.image
      this.selectedVideo = !!newVal.video
      this.selectedAudio = !!newVal.audio
      this.selectedFile = !!newVal.file
    },
    'question.numberOfChoices': function (newVal, _) {
      const oldVal = this.question.choices.length
      if (oldVal > newVal) {
        this.question.choices.splice(newVal)
      } else {
        for (let i = oldVal; i < newVal; i++) {
          this.question.choices.push(new LocalizedString())
        }
      }
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          if (!this.selectedAudio) this.question.audio = null
          if (!this.selectedVideo) this.question.video = null
          if (!this.selectedImage) this.question.image = null
          if (!this.selectedFile) this.question.file = null
          this.$emit('on-submit')
        }
      })
    },

    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
