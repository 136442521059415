// class wrapper for a string that can be in multiple languages
// currently supported languages are English (en), Arabic (ar), and French (fr)
import { languages } from "prismjs";
import { LanguagesDict, Languages } from "./Languages";
// map every language code to an empty string to be used as a default value

// the class should have a property for each language, and a property for the default language.
// the class should have a .json() method that returns the object in JSON format, trimming all the languages with empty strings
class LocalizedString {
  constructor(langs = {}) {
    for (let lang of Languages) {
      this[lang.code] = langs[lang.code] || "";
    }
    this.default = langs.default || "en";
  }
  json() {
    let obj = {};
    for (let lang of Languages) {
      if (this[lang.code] !== "") {
        obj[lang.code] = this[lang.code];
      }
    }
    return obj;
  }
}
export default LocalizedString;
