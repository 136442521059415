<template>
  <b-row>
    <b-col
      sm="12"
      class="p-relative"
    >
      <b-progress
        v-if="uploadProgress > 0"
        class="questions__progress"
        :value="uploadProgress"
        :max="100"
      />
    </b-col>
    <b-col
      md="1"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="showModalAddUser()"
        >
          Create
        </b-button>
      </b-form-group>
    </b-col>
    <b-col
      md="1"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="showImportModal"
        >
          Import
        </b-button>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <label
          class="d-inline-block text-sm-left mr-50"
          for="perPageSelect"
        >Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="tableItems"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(title)="data">
          {{
            Object.keys(data.item.title.json())
              .map((key) => data.item.title[key])
              .join(", ")
          }}
        </template>
        <template #cell(image)="data">
          <b-img
            v-if="data.item.image"
            left
            class="mb-1 mb-sm-0"
            height="40"
            :src="data.item.image"
            alt="Left image')"
          />
          <a
            v-if="data.item.file"
            :href="data.item.file"
            target="_blank"
          >
            <feather-icon
              size="20"
              icon="FileIcon"
              class="mr-50"
            />
          </a>

          <b-embed
            v-if="data.item.video"
            type="iframe"
            aspect="16by9"
            :src="data.item.video"
            allowfullscreen
          />
        </template>

        <template #cell(choices)="data">
          <b-row>
            <span
              style="color: red"
              @click="showModelListChoices(data)"
            >
              ({{ data.value.length }}) Choices</span>
          </b-row>
        </template>

        <template #cell(action)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModalUpdateQuestion(data.item)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item @click="duplicateQuestion(data.item)">
                <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
                />
                <span>Duplicate</span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item @click="showConfirmDeleteQuestion(data)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
    </b-col>

    <!-- modal add Question -->

    <question-modal
      ref="my-modal-add-question"
      :question="question"
      :research-has-auto-correct="currentResearchCorrect"
      :research-type="currentResearchType"
      title="Add Question"
      :languages="languages"
      @reset-modal="resetModal"
      @on-submit="saveQuestion()"
    />
    <b-modal
      id="import-modal"
      ref="import-modal"
      title="Import Questions"
    >
      <import-questions
        ref="import-modal"
        :current-research-type="currentResearchType"
        :research-i-d="researchID"
        :user-id="userId"
        :language="languages[0]"
        @refresh-questions="getRows"
      />
    </b-modal>
    <!-- modal update question -->
    <question-modal
      ref="my-modal-update-question"
      :question="question"
      :research-has-auto-correct="currentResearchCorrect"
      title="Update Question"
      :languages="languages"
      :research-type="currentResearchType"
      @reset-modal="resetModal"
      @on-submit="saveQuestion()"
    />
    <!-- modal list cards -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-user-choices"
      title="Choices"
      ok-only
      ok-title="ok"
    >
      <div
        v-for="lang in languages"
        :key="lang.code"
      >
        <span
          class="pl-1"
          style="color: red"
        >
          Choices in {{ LanguagesDict[lang].name }}
        </span>
        <div
          v-for="(item, index) in currentChoices"
          :key="item.id"
        >
          <b-row class="py-1 pl-1">
            <span class="pl-1">
              {{ "Choice " + (index + 1) + ": " + item[lang] }}
            </span>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-col cols="1">
      {{ currentPage !== Math.ceil(totalRows / perPage) ? perPage : totalRows % perPage }} - {{ totalRows }}
    </b-col>
    <b-col cols="11">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
/* global Backendless */

import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BEmbed,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@validations'
import Question from '@/utils/Question'
import LocalizedString from '@/utils/LocalizedString'
import { LanguagesDict } from '@/utils/Languages'
import uploadFile from '@/utils/upload'
import ImportQuestions from '@/views/pages/Questions/Modals/ImportQuestions.vue'
import research from '@/utils/Research'
import QuestionModal from './Modals/QuestionModal.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BEmbed,
    BDropdown,
    BDropdownItem,
    QuestionModal,
    ImportQuestions,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      LanguagesDict,
      question: new Question(),
      mytoaster: null,
      username: '',
      researchID: '',
      choice4Ar: '',
      password: '',
      languages: ['en', 'aa'],
      user: '',
      cardsParseObjects: [],
      currentChoices: [],
      titleEnState: null,
      titleArState: null,
      descriptionEnState: null,
      descriptionArState: null,
      numberOfChoicesState: null,
      nameState: null,
      emailState: null,
      passwordState: null,
      perPage: 50,
      pageOptions: [50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // { key: "id", label: "Id", sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        // { key: "titleAr", label: "Title AR", sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        // { key: "researchID", label: "ResearchID", sortable: true },
        { key: 'image', label: 'image', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'numberOfChoices', label: 'Number  Of Choices', sortable: true },
        { key: 'answerChoiceNum', label: 'Answer Choice Num', sortable: true },
        { key: 'order', label: 'Sorting Order', sortable: true },
        'choices',
        'action',
      ],
      questions: [],
      researchesTypes: [],
      currentResearch: '',
      currentResearchType: '',
      currentResearchCorrect: false,
      cards: [],
      selectedResearch: null,
      selected1: null,

      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      userId: Backendless.LocalCache.get('current-user-id'),
      uploadProgress: 0,
    }
  },
  computed: {
    research() {
      return research
    },
    choices() {
      return this.question.choices.json()
    },
    tableItems() {
      return this.questions.map(question => ({
        titleEn: question.title.en,
        titleAr: question.title.ar,
        descriptionAr: question.description.ar,
        descriptionEn: question.description.en,
        ...question,
      }))
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    selectedImage() {
      return (
          this.question.image?.trim?.().length || this.question.image != null
      )
    },
    selectedVideo() {
      return (
          this.question.video?.trim?.().length || this.question.video != null
      )
    },
    selectedAudio() {
      return (
          this.question.audio?.trim?.().length || this.question.audio != null
      )
    },
    selectedFile() {
      return (
          this.question.file?.trim?.().length || this.question.file != null
      )
    },
  },
  watch: {},
  mounted() {
    // Set the initial number of items

    this.researchID = this.$route.params.id
    this.ResearchesTypes()

    this.getRows()
  },
  methods: {
    showImportModal() {
      this.$refs['import-modal'].show()
    },
    handleProgress(progressEvent) {
      this.uploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      if (this.uploadProgress === 100) {
        this.uploadProgress = 0
        this.mytoaster = this.$toast({
          component: ToastificationContent,
          props: {
            dismissible: true,
            title: 'File uploaded successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
    makeId(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
        counter += 1
      }

      const currentTimestamp = moment(new Date()).format(
        'MM_DD_YYYY_h_mm_ss_SSS',
      )
      return result + currentTimestamp
    },
    async saveQuestion(qtype) {
      console.log(qtype)
      const that = this

      that.$refs['my-modal-add-question'].hide()
      this.$refs['my-modal-update-question'].hide()

      async function handleUploads() {
        if (this.selectedImage || this.selectedVideo || this.selectedAudio || this.selectedFile) {
          this.mytoaster = that.$toast({
            component: ToastificationContent,
            props: {
              dismissible: true,
              title: 'Uploading file',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          const mediaTypes = [
            { key: 'image', selected: this.selectedImage },
            { key: 'video', selected: this.selectedVideo },
            { key: 'audio', selected: this.selectedAudio },
            { key: 'file', selected: this.selectedFile },
          ]

          const uploads = mediaTypes.filter(media => media.selected && this.question[media.key] instanceof File)
            .map(async media => {
              try {
                const response = await uploadFile(
                  this.question[media.key],
                  `/myfiles/${this.makeId(7)}`,
                  this.handleProgress,
                )
                this.question[media.key] = response.data.fileURL
              } catch (error) {
                console.error(`Upload failed for ${media.key}:`, error)
              }
            })

          await Promise.all(uploads)
        }
      }

      await handleUploads.call(this)
      const question = {
        Type: this.question.type ?? this.currentResearchType,
        ResearchID: this.researchID,
        TitleEN: this.question.title.en,
        TitleAR: this.question.title.ar,
        DescriptionEN: this.question.description,
        title: this.question.title.json(),
        NumberOfChoices: parseInt(this.question.numberOfChoices, 10),
        Choices: this.question.choices.map(choice => choice.json()),
        AnswerChoiceNum: this.question.answerChoiceNum,
        Image: this.question.image ?? null,
        Video: this.question.video ?? null,
        Audio: this.question.audio ?? null,
        File: this.question.file ?? null,
        Timer: this.question.hasTimer,
        ownerId: this.userId,
        order: parseInt(this.question.order, 10),
      }
      if (this.question.id?.trim?.().length) {
        question.objectId = this.question.id
      }

      Backendless.Data.of('Questions')
        .save(question)
        .then(savedObject => {
          that.currentResearch.questions.push(savedObject)

          const children = that.currentResearch.questions

          Backendless.Data.of('Researches')
            .setRelation(that.currentResearch, 'questions', children)
            .then(() => {
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Question added Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.getRows()
            })
            .catch(error => {
              console.log(`server reported an error - ${error.message}`)
            })
        })
        .catch(error => {
          console.log(`an error research has occurred ${error.message}`)
        })
    },
    showModelListChoices(research) {
      this.currentChoices = research.item.choices
      if (this.currentChoices.length > 0) {
        this.$refs['my-modal-user-choices'].show()
      }
    },
    showModalUpdateQuestion(question) {
      this.question = new Question(question)

      this.$refs['my-modal-update-question'].show()
    },
    duplicateQuestion(question) {
      delete question.id
      this.question = new Question(question)

      this.$refs['my-modal-update-question'].show()
    },
    showModalAddUser() {
      this.question = new Question()
      this.$refs['my-modal-add-question'].show()
    },
    resetModal() {
      this.name = ''
      this.email = ''
      this.user = ''
      this.nameState = null
      this.emailState = null
      this.passwordState = null
    },

    showConfirmDeleteQuestion(question) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete Question ${question.item.titleEn} .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            const that = this
            Backendless.Data.of('Questions')
              .remove({ objectId: question.item.id })
              .then(() => {
                // The object was deleted from the Parse Cloud.
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Question Deleted Successfully',
                    icon: 'BellIcon',
                    text: question.item.titleEn,
                    variant: 'success',
                  },
                })
                that.getRows()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getRows() {
      // get users from server

      const that = this
      that.researchesTypes = []
      that.questions = []
      const query = Backendless.Data.of('Researches')
      if (this.$route.params.id != null) {
        query
          .findById(this.$route.params.id, {
            relations: ['localizations'],
          })
        // .findByIdSync({ objectId: this.$route.params.id, })
          .then(research => {
            // Contact instance has been found by its objectId
            this.currentResearch = research
            this.currentResearch.questions = []
            this.languages = research.localizations.map(localization => localization.lang)
            this.currentResearchType = research.ResearchType
            this.currentResearchCorrect = research.autoCorrect

            // Mark:- query all question
            const loadRelationsQueryBuilder = Backendless.LoadRelationsQueryBuilder.create()
            loadRelationsQueryBuilder.setRelationName('questions')
            loadRelationsQueryBuilder.setPageSize(100).setOffset(0)

            Backendless.Data.of('Researches')
              .loadRelations(this.$route.params.id, loadRelationsQueryBuilder)
              .then(relatedObjectsArray => {
                that.totalRows = relatedObjectsArray.length
                that.questions = relatedObjectsArray
                  .filter(element => element.dn !== 'SPAM ')
                  .map(
                    element => new Question({
                      id: element.objectId, // get("objectId"),
                      title: new LocalizedString(element.title),
                      type: element.Type,
                      researchID: element.ResearchID,
                      image: element.Image,
                      video: element.Video,
                      audio: element.Audio,
                      file: element.file,
                      description: element.DescriptionEN,
                      numberOfChoices: element.NumberOfChoices,
                      choices: element.Choices.map(choice => new LocalizedString(choice)),
                      answerChoiceNum: element.AnswerChoiceNum,
                      hasTimer: element.Timer,
                      order: element.order,
                    }),
                  )

                that.currentResearch.questions = relatedObjectsArray
              })
              .catch(error => {
                console.error(error)
              })
          })
          .catch(error => {
            console.error(error)
            // an error has occurred, the error code can be retrieved with fault.statusCode
          })
      }
    },
    ResearchesTypes() {
      // get users from server
      const that = this
      that.researchesTypes = []
      const query = Backendless.Data.of('ResearchesTypes')
      query
        .find()
        .then(results => {
          results.forEach(element => {
            this.researchesTypes.push({
              name: element.name,
            })
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";

.questions__progress {
  position: absolute;
  top: -11px;
  left: 13px;
  width: 98%;
}
</style>
